import React from 'react';

import { App } from 'modules/app';
import { MainNavigation } from 'modules/navigation';
import { StemiLabPage } from 'modules/lab';

class StemiLab extends React.Component {
  render() {
    return (
      <App>
        <MainNavigation />
        <StemiLabPage />
      </App>
    );
  }
}

export default StemiLab;
